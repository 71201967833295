export const GET_ISSUERS = 'GET_ISSUERS'
export const GET_SECONDARY_PARTY_OPTIONS = 'GET_SECONDARY_PART_OPTIONS'
export const GET_SUSTAINABLE_DEVELOPMENT_GOALS = 'GET_SUSTAINABLE_DEVELOPMENT_GOALS'
export const GET_UOP_CATEGORIES = 'GET_UOP_CATEGORIES'
export const GET_INSTRUMENTS = 'GET_INSTRUMENTS'
export const UPDATE_FRAMEWORK = 'UPDATE_FRAMEWORK'
export const UPDATE_UOP_FRAMEWORK = 'UPDATE_UOP_FRAMEWORK'
export const UPDATE_SLB_FRAMEWORK = 'UPDATE_SLB_FRAMEWORK'
export const SET_FRAMEWORK = 'SET_FRAMEWORK'
export const RESET_FRAMEWORK = 'RESET_FRAMEWORK'
export const UPDATE_FRAMEWORK_JSON = 'UPDATE_FRAMEWORK_JSON'
export const UPDATE_ASSESSMENT = 'UPDATE_ASSESSMENT'
export const SET_ASSESSMENT = 'SET_ASSESSMENT'
export const RESET_ASSESSMENT = 'RESET_ASSESSMENT'
export const UPDATE_ASSESSMENT_JSON = 'UPDATE_ASSESSMENT_JSON'
export const UPDATE_APP_USERS = 'UPDATE_APP_USERS'