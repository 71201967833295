const localConfig = {
  REACT_APP_AZURE_CLIENT_ID: '4a6d9a7b-1600-4530-9bbc-2e9a16327f26',
  REACT_APP_API_URL: 'https://as-mg-dev1-t1-weu-evaluate-api-01.azurewebsites.net',
  REACT_APP_URL: 'https://as-mg-dev1-t1-weu-evaluate-app-01.azurewebsites.net',
  //REACT_APP_API_URL: 'https://evaluatesrch.search.windows.net',
  REACT_APP_AZURE_REDIRECT_URL: 'http://localhost:3000',
  APP_NAME: 'E-Valuate',
  REACT_APP_AZURE_AUTHORITY:
    'https://login.microsoftonline.com/aa42167d-6f8d-45ce-b655-d245ef97da66',
  REACT_APP_AZURE_SCOPE: 'https://savingsandinvestments.onmicrosoft.com/sp-mg-dev1-t1-evaluate-ui-01/user_impersonation'
}

export const getConfig = () => {
  if (process.env.NODE_ENV === 'development') {
    return localConfig
  } else {
    return window.config
  }
}
