import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { getConfig } from 'configs/config'

const config = getConfig()

export const msalConfig = {
  auth: {
    clientId: config.REACT_APP_AZURE_CLIENT_ID,
    authority: config.REACT_APP_AZURE_AUTHORITY, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: config.REACT_APP_AZURE_REDIRECT_URL
  },
  system: {
    allowRedirectInIframe: true
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
}

//Add scopes here for ID token to be used at Microsoft identity platform endpoints.

export const loginRequest = {
  scopes: [config.REACT_APP_AZURE_SCOPE]
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value'
}

export const acquireToken = (instance, accounts, setUserContext) => {
  const request = {
    ...loginRequest,
    account: accounts[0]
  }
  instance
    .acquireTokenSilent(request)
    .then(accessTokenResponse => {
      // Acquire token silent success
      const accessToken = accessTokenResponse.accessToken
      const tokenParts = accessTokenResponse.accessToken.split('.')
      const tokenPayload = JSON.parse(window.atob(tokenParts[1]))
      setUserContext({ token: accessToken, roles: tokenPayload.roles })
      //console.log(tokenPayload.roles)
    })
    .catch(error => {
      console.log(error)
      if (error instanceof InteractionRequiredAuthError) {
        instance
          .acquireTokenPopup(accessTokenRequest)
          .then(function (accessTokenResponse) {
            // Acquire token interactive success
            const accessToken = accessTokenResponse.accessToken
            setUserContext({ token: accessToken })
          })
          .catch(function (error) {
            // Acquire token interactive failure
            console.log(error)
          })
      }
      console.log(error)
    })
}
