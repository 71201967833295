import {
    UPDATE_APP_USERS
  } from '../../types/appConstants'
  
  // **  Initial State
  const initialState = { 
    appUsers: []
  }
  
  const appUsersReducer = (state = initialState, action) => {  
    switch (action.type) {
      case UPDATE_APP_USERS:      
        return { ...state, appUsers: [...action.payload] } 
      default:
        return state
    }
  }
  
  export default appUsersReducer
  