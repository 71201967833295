import './style.css'
import { useEffect, useState } from 'react'
import { useSkin } from '../../../utility/hooks/useSkin'
import SplashAnimation from './SplashAnimation'

const SplashScreen = () => {
  const [skin, setSkin] = useSkin()

  const [show, setShow] = useState(true)

  useEffect(() => {
    document.body.classList.add('overflow-hidden')
    const loadingTimeout = setTimeout(() => {
      setShow(false)
    }, 7000) // Show main component after 5 seconds (5000 milliseconds)

    return () => clearTimeout(loadingTimeout)
  }, [])

  useEffect(() => {
    // Add the CSS class to disable scrolling when loading is true
    if (show) {
      document.body.classList.add('overflow-hidden')
    } else {
      // Remove the CSS class to enable scrolling when loading is false
      document.body.classList.remove('overflow-hidden')
    }
  }, [show])

  return !show ? (
    <></>
  ) : (
    <div
      className={`fallback-spinner vh-100 d-flex align-items-center justify-content-center ${
        skin === 'dark' ? 'splash-screen-dark' : 'splash-screen'
      }`}>
      <SplashAnimation />
    </div>
  )
}

export default SplashScreen
