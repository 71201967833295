import {
  UPDATE_ASSESSMENT,
  SET_ASSESSMENT,
  RESET_ASSESSMENT,
  UPDATE_ASSESSMENT_JSON
} from '../../types/appConstants'

// **  Initial State
const initialState = { }

const assessmentReducer = (state = initialState, action) => {  
  switch (action.type) {
    case UPDATE_ASSESSMENT:      
      return { ...state, ...action.payload }          
    case UPDATE_ASSESSMENT_JSON:
      return { ...state, 
         jsonFields: {
         ...state.jsonFields,
         ...action.payload
       } 
      }      
    case SET_ASSESSMENT:
      return { ...state, ...action.payload }
    case RESET_ASSESSMENT:      
      return { }
    default:
      return state
  }
}

export default assessmentReducer
