// You can customize the template with the help of this file

//Template config options
const themeConfig = {
  app: {
    appName: 'E-Valuate',
    appLogoImage: require('@src/assets/images/logo/E-Valuate.png').default,
    appLogoImageSM: require('@src/assets/images/logo/E-Valuate-sm.png').default,
    logoImage: require('@src/assets/images/logo/E-ValuateName.png').default,
    logoImage_e: require('@src/assets/images/logo/images/E.png').default,
    logoImage_dash: require('@src/assets/images/logo/images/dashvaluate.png').default,
    logoImage_v: require('@src/assets/images/logo/images/3-v.png').default,
    logoImage_a: require('@src/assets/images/logo/images/4-a.png').default,
    logoImage_l: require('@src/assets/images/logo/images/5-l.png').default,
    logoImage_u: require('@src/assets/images/logo/images/6-u.png').default,
    logoImage_aa: require('@src/assets/images/logo/images/7-a.png').default,
    logoImage_t: require('@src/assets/images/logo/images/8-t.png').default,
    logoImage_ee: require('@src/assets/images/logo/images/9-e.png').default,
    logoImage_loader: require('@src/assets/images/logo/ajax-loader.png').default
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'sticky', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: true,
    scrollTop: true // Enable scroll to top button
  }
}

export default themeConfig
